export const navItems = [
  "Venue and Registration",
  "Important Dates",
  "Reviewer Instructions",
  "AC Instructions",
  "Code of Conduct",
  "Conflicts of Interest",
  "Sponsors",
];

export const callItems = {
  '2022': [
    {
        dropdown: 'Calls',
        items: [
          'Call for Papers',
        ]
    }
  ],
  '2023': [
    {
        dropdown: 'Calls',
        items: [
          'Call for Papers',
          'Call for Datasets',
        ]
    }
  ],
  '2024': [
    {
        dropdown: 'Calls',
        items: [
          'Call for Papers',
        ]
    }
  ],
  '2025': [
    {
        dropdown: 'Calls',
        items: [
          'Call for Papers',
        ]
    }
  ],

}


export const programItems = {
  '2022': [
    {
      dropdown: 'Program',
      items: [
        'Full Agenda',
        'Plenary Speakers',
        'Accepted Papers',
        ]
    },
  ],
  '2023': [
    {
      dropdown: 'Program',
      items: [
        'Full Agenda',
        'Plenary Speakers',
        'Accepted Papers',
        'Accepted Datasets',
        'Presentation Instructions',
        'Online Schedule',
        'Social Activities',
        ]
    }
  ],
  '2024': [
    {
      dropdown: 'Program',
      items: [
        'Full Agenda',
        ]
    }
  ],
 '2025': [
    {
      dropdown: 'Program',
      items: [
        'Full Agenda',
        // 'Plenary Speakers',
        // 'Accepted Papers',
        // 'Accepted Datasets',
        // 'Presentation Instructions',
        // 'Online Schedule',
        // 'Social Activities',
        ]
    }
  ],


}




export const yearItems = [
  {
    dropdown: 'Year',
    items: [
      '2025',
      '2024',
      '2023',
      '2022',
    ]
  },
]

export const dropItems = {
  '2022': [
    {
      dropdown: 'Organizers',
      items: [
        'Organizing Committee',
        'Area Chairs',
        'Program Committee',
        'Advisory Board',
      ]
    },
  ],
  '2023': [
    {
      dropdown: 'Organizers',
      items: [
        'Organizing Committee',
        'Area Chairs',
        'Advisory Board',
        'Student Volunteers',
      ]
    },
  ], 
  '2024': [
    {
      dropdown: 'Organizers',
      items: [
        'Organizing Committee',
        'Area Chairs',
        'Advisory Board',
        'Student Volunteers',
      ]
    },
  ],
  '2025': [
    {
      dropdown: 'Organizers',
      items: [
        'Organizing Committee',
        // 'Area Chairs',
        // 'Advisory Board',
        // 'Student Volunteers',
      ]
    },
  ]

}


