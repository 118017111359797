export const array2022 = [
  {
    position: "General and Program Chairs",
    persons: [
      {
        name: "Bernhard Schölkopf",
        href: "https://www.is.mpg.de/~bs",
        address: "Max Planck Institute for Intelligent Systems, Germany",
      },
       {
        name: "Caroline Uhler",
        href: "https://www.carolineuhler.com/",
        address: "Massachusetts Institute of Technology, USA",
      },
      {
        name: "Kun Zhang",
        href: "http://www.andrew.cmu.edu/user/kunz1/",
        address: "Carnegie Mellon University, USA",
      },
    ],
  },
  {
    position: "Logistics and Conference Planning Team",
    persons: [
      {
        name: "Silvia Chiappa (Co-Chair)",
        href: "https://csilviavr.github.io/",
        address: "DeepMind, UK",
      },
      {
        name: "Frederick Eberhardt (Co-Chair)",
        href: "http://www.its.caltech.edu/~fehardt/",
        address: "California Institute of Technology, USA",
      },
       {
        name: "Patrick Burauel",
        href: "https://patrick-burauel.netlify.app/",
        address: "California Institute of Technology, USA",
      },
      {
        name: "Julius von Kügelgen",
        href: "https://sites.google.com/view/julius-von-kuegelgen/home",
        address: "Max Planck Institute for Intelligent Systems, Germany",
      },
    ],
  },
  {
    position: "Publication Chairs",
    persons: [
      {
        name: "Zhijing Jin",
        href: "https://zhijing-jin.com/fantasy/",
        address: "Max Planck Institute for Intelligent Systems, Germany",
      },
      {
        name: "Alexander D'Amour",
        href: "https://www.alexdamour.com/",
        address: "Google Brain, USA",
      },
    ],
  },
  {
    position: "Communication Chairs",
    persons: [
      {
        name: "Liam Solus",
        href: "https://people.kth.se/~solus/",
        address: "KTH Royal Institute of Technology, Sweden",
      },
      {
        name: "Nan Rosemary Ke",
        href: "https://nke001.github.io/",
        address: "Mila, Canada",
      },
    ],
  },
  {
    position: "Sponsorship Chairs",
    persons: [
      {
        name: "Sara Magliacane",
        href: "https://smaglia.wordpress.com/",
        address: "University of Amsterdam, Netherlands",
      },
      {
        name: "Francesco Locatello",
        href: "https://scholar.google.com/citations?user=wQanfTIAAAAJ&hl=en",
        address: "Institute of Science and Technology, Austria",
      },
    ],
  },
  {
    position: "Social Chairs",
    persons: [
      {
        name: "Stefan Bauer",
        href: "https://scholar.google.com/citations?user=O-oICE8AAAAJ&hl=de",
        address: "KTH Royal Institute of Technology, Sweden",
      },
      {
        name: "Atalanti Mastakouri",
        href: "https://www.is.mpg.de/person/amastakouri",
        address: "Amazon Research Tübingen, Germany",
      },
    ],
  },
  {
    position: "Workflow Chairs",
    persons: [
      {
        name: "Biwei Huang",
        href: "https://biweihuang.com/",
        address: "Carnegie Mellon University, USA",
      },
      {
        name: "Daniel Malinsky",
        href: "http://www.dmalinsky.com/",
        address: "Columbia University, USA",
      },
    ],
  },
  {
    position: "Online Chairs",
    persons: [
      {
        name: "Christina Heinze-Deml",
        href: "https://stat.ethz.ch/~heinzec/",
        address: "ETH Zurich, Switzerland",
      },
      {
        name: "Sebastian Weichwald",
        href: "https://sweichwald.de/",
        address: "University of Copenhagen, Denmark",
      },
    ],
  },
  {
    position: "Publicity Chair",
    persons: [
      {
        name: "Mingming Gong",
        href: "https://mingming-gong.github.io/",
        address: "The University of Melbourne, Australia",
      },
    ],
  },
];

export const array2023 = [
  {
    position: "General Chairs",
    persons: [
      {
        name: "Francesco Locatello",
        href: "https://www.francescolocatello.com/",
        address: "Institute of Science and Technology, Austria",
      },
       {
        name: "Peter Spirtes",
        href: "https://www.cmu.edu/dietrich/philosophy/people/faculty/spirtes.html",
        address: "CMU",
      },
    ],
  },
  {
    position: "Program Chairs",
    persons: [
      {
        name: "Mihaela van der Schaar",
        href: "https://www.vanderschaar-lab.com/",
        address: "University of Cambridge",
      },
       {
        name: "Cheng Zhang",
        href: "https://cheng-zhang.org/",
        address: "Microsoft Research",
      },
      {
        name: "Dominik Janzing",
        href: "https://janzing.github.io/",
        address: "Amazon Web Services",
      },
    ],
  },
  {
    position: "Sponsorship Chairs",
    persons: [
      {
        name: "Alexandre d'Amour",
        href: "https://www.alexdamour.com/",
        address: "Google",
      },
      {
        name: "Dhanya Sridhar",
        href: "https://www.dsridhar.com/",
        address: "University of Montreal",
      },
    ],
  },
  {
    position: "Social Chairs",
    persons: [
      {
        name: "Atalanti Mastakouri",
        href: "https://atalanti.github.io/",
        address: "Amazon Web Services",
      },
      {
        name: "Julius von Kugelgen",
        href: "https://sites.google.com/view/julius-von-kuegelgen/home",
        address: "Max Planck Institute for Intelligent Systems",
      },
    ],
  },
  {
    position: "Publication Chairs",
    persons: [
      {
        name: "Michel Besserve",
        href: "https://ei.is.mpg.de/person/besserve",
        address: "Max Planck Institute for Intelligent Systems",
      },
      {
        name: "Amit Sharma",
        href: "http://www.amitsharma.in/",
        address: "Microsoft Research",
      },
    ],
  },
  {
    position: "Communication Chairs",
    persons: [
      {
        name: "Sara Magliacane",
        href: "https://smaglia.wordpress.com/",
        address: "University of Amsterdam",
      },
      {
        name: "Biwei Huang",
        href: "https://biweihuang.com/",
        address: "UC San Diego",
      },
    ],
  },
  {
    position: "Workflow Chairs",
    persons: [
      {
        name: "Elena Zheleva",
        href: "https://www.cs.uic.edu/~elena/",
        address: "University of Illinois at Chicago",
      },
      {
        name: "Daniel Malinsky",
        href: "http://www.dmalinsky.com/",
        address: "Columbia University",
      },
    ],
  },
  {
    position: "Logistics and Conference Planning Team",
    persons: [
      {
        name: "Chaochao Lu",
        href: "https://causallu.com/",
        address: "University of Cambridge",
      },
      {
        name: "Dominik Zietlow",
        href: "https://scholar.google.de/citations?user=jkIx0f8AAAAJ&hl=de",
        address: "Amazon Web Services",
      },
      {
        name: "Jeroen Berrevoets",
        href: "https://www.maths.cam.ac.uk/person/jb2384",
        address: "University of Cambridge",
      },
    ],
  },
  {
    position: "Online Chairs",
    persons: [
      {
        name: "David Arbour",
        href: "http://darbour.github.io/",
        address: "Adobe",
      },
      {
        name: "Johann Brehmer",
        href: "https://johannbrehmer.github.io/",
        address: "Qualcomm AI Research Amsterdam",
      },
    ],
  },
  {
    position: "Publicity Chairs",
    persons: [
      {
        name: "Ignavier Ng",
        href: "https://ignavierng.github.io/",
        address: "CMU",
      },
      {
        name: "Guangyi Chen",
        href: "https://chengy12.github.io/",
        address: "MBZUAI and CMU",
      },
    ],
  },
  {
    position: "Dataset Chairs",
    persons: [
      {
        name: "Ricardo Monti",
        href: "http://www.gatsby.ucl.ac.uk/~ricardom/",
        address: "University College London",
      },
      {
        name: "Chao Ma",
        href: "https://chao-ma.org/",
        address: "University of Cambridge",
      },
    ],
  },
];

export const array2024 = [
  {
    position: "General Chairs",
    persons: [
      {
        name: "Aditya Grover",
        href: "https://aditya-grover.github.io/",
        address: "UCLA",
      },
       {
        name: "Cheng Zhang",
        href: "https://cheng-zhang.org/",
        address: "Microsoft Research",
      },
    ],
  },
  {
    position: "Program Chairs",
    persons: [
      {
        name: "Vanessa Didelez",
        href: "https://people.maths.bris.ac.uk/~maxvd/",
        address: "University of Bremen",
      },
      {
        name: "Francesco Locatello",
        href: "https://www.francescolocatello.com/",
        address: "Institute of Science and Technology, Austria",
      },
    ],
  },
  {
    position: "Sponsorship Chairs",
    persons: [
      {
        name: "Silvia Chiappa",
        href: "https://csilviavr.github.io/",
        address: "DeepMind, UK",
      },
      {
        name: "Wei Chen",
        href: "https://weichen-cas.github.io",
        address: "Chinese Academy of Sciences",
      },
      {
        name: "Dhanya Sridhar",
        href: "https://www.dsridhar.com",
        address: "Université de Montréal",
      },
    ],
  },
  {
    position: "Social Chairs",
    persons: [
      {
        name: "Zachary Lipton",
        href: "https://www.zacharylipton.com/",
        address: "CMU",
      },
      {
        name: "Elena Zheleva",
        href: "https://www.cs.uic.edu/~elena/",
        address: "University of Illinois at Chicago",
      },
      {
        name: "Fiona Guo",
        address: "University of South California",
      },
    ],
  },
  {
    position: "Publication Chairs",
    persons: [
      {
        name: "Joris Mooij",
        href: "https://staff.fnwi.uva.nl/j.m.mooij/",
        address: "University of Amsterdam",
      },
      {
        name: "Sourbh Bhadane",
        href: "http://sourbhbh.github.io",
        address: "Cornell University",
      },
    ],
  },
  {
    position: "Communication Chairs",
    persons: [
      {
        name: "Mingming Gong",
        href: "https://mingming-gong.github.io",
        address: "University of Melbourne",
      },
      {
        name: "Matt Kusner",
        href: "https://mkusner.github.io",
        address: "University College London",
      },
    ],
  },
  {
    position: "Workflow Chairs",
    persons: [
      {
        name: "Virginia Aglietti",
        href: "https://virgiagl.github.io/",
        address: "DeepMind",
      },
      {
        name: "Vasilis Syrgkanis",
        href: "https://vsyrgkanis.com/",
        address: "Stanford University",
      },
      {
        name: "Ruibo Tu",
        href: "https://www.kth.se/profile/ruibo?l=en",
        address: "KTH Royal Institute of Technology",
      },
    ],
  },
  {
    position: "Logistics and Conference Planning Team",
    persons: [
      {
        name: "Krikamol Muandet",
        href: "https://www.krikamol.org/",
        address: "CISPA Helmholtz Center for Information Security",
      },
      {
        name: "Chi Zhang",
        href: "https://web.cs.ucla.edu/~zccc/",
        address: "UCLA",
      },
    ],
  },
  {
    position: "Online Chairs",
    persons: [
      {
        name: "Gaurav Sinha",
        href: "https://sinhagaurav.github.io/",
        address: "Microsoft Research India",
      },
      {
        name: "Biwei Huang",
        href: "https://biweihuang.com",
        address: "UCSD",
      },
    ],
  },
    {
    position: "Website Chairs",
    persons: [
      {
        name: "Maggie Makar",
        href: "https://mymakar.github.io/",
        address: "University of Michigan, Ann Arbor",
      },
      {
        name: "Ang Li",
        href: "https://www.causalds.org",
        address: "Florida State University",
      },
    ],
  },

  {
    position: "Dataset Chairs",
    persons: [
      {
        name: "Ricardo Monti",
        href: "http://www.gatsby.ucl.ac.uk/~ricardom/",
        address: "University College London",
      },
      {
        name: "Cian Eastwood",
        href: "https://cianeastwood.github.io/",
        address: "University of Edinburgh and Max Planck Institute, Tübingen",
      },
    ],
  },
  {
    position: "Operations Chairs",
    persons: [
      {
        name: "Hritik Bansal",
      },
      {
        name: "Daniel Israel",
      },
      {
        name: "Shufan Li",
      },
      {
        name: "Tung Nguyen",
      },
      {
        name: "Prachee Sharma",
      },
      {
        name: "Jia-Hang Sha",
      },
      {
        name: "Siyan Zhao",
      },
    ],
  },
];



export const array2025 = [
    {
    position: "General Chairs",
    persons: [
      {
        name: "Negar Kiyavash",
        href: "https://people.epfl.ch/negar.kiyavash?lang=en",
        address: "EPFL",
      },
       {
        name: "Jin Tian",
        href: "https://faculty.sites.iastate.edu/jtian/",
        address: "Iowa State University",
      },
    ],
  },
  {
    position: "Program Chairs",
    persons: [
      {
        name: "Mathias Drton",
        href: "https://www.professoren.tum.de/en/drton-mathias",
        address: "Technical University of Munich",
      },
      {
        name: "Biwei Huang",
        href: "https://biweihuang.com/",
        address: "University of California San Diego",
      },
    ],
  },
  {
    position: "Publication Chairs",
    persons: [
      {
        name: "Sourbh Bhadane",
        href: "http://sourbhbh.github.io/",
        address: "University of Amsterdam",
      },
      {
        name: "Thijs van Ommen",
        href: "https://www.uu.nl/staff/MOmmen",
        address: "Utrecht University",
      },
      {
        name: "Chandler Squires",
        href: "https://www.chandlersquires.com",
        address: "MIT",
      },
    ],
  },
  {
    position: "Communication Chairs",
    persons: [
      {
        name: "Sara Magliacane",
        href: "https://saramagliacane.github.io/",
        address: "University of Amsterdam",
      },
      {
        name: "Zhijing Jing",
        href: "https://zhijing-jin.com/fantasy/",
        address: "University of Toronto",
      },
    ],
  },
  {
    position: "Workflow Chairs",
    persons: [
      {
        name: "Virginia Aglietti",
        href: "https://virgiagl.github.io/",
        address: "DeepMind",
      },
      {
        name: "Urmi Ninad",
        href: "https://climateinformaticslab.com/about/",
        address: "TU Berlin",
      },
      {
        name: "Yujia Zheng",
        href: "http://yjzheng.com/",
        address: "Carnegie Mellon University",
      },
    ],
  },
  {
    position: "Website Chairs",
    persons: [
      {
        name: "Maggie Makar",
        href: "https://mymakar.github.io/",
        address: "University of Michigan, Ann Arbor",
      },
      {
        name: "Zeyu Tang",
        href: "https://zeyu.one/",
        address: "Carnegie Mellon University",
      },
    ],
  },
  {
    position: "Online Chairs",
    persons: [
      {
        name: "Sadegh Khorasani",
        href: "https://people.epfl.ch/sadegh.khorasani?lang=en",
        address: "EPFL",
      },
      {
        name: "Nils Sturma",
        href: "https://nilssturma.github.io/",
        address: "TU Munich",
      }
    ]
  }
];

